<template>
    <div class="job-label">
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>职位标签</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-card class="box-card" style="min-height: 100%">
            <div class="create"><el-button type="primary" icon="el-icon-circle-plus-outline" @click="openJobLabel">新增职位标签</el-button></div>
             <el-table
                :data="jobTags"
                border
                stripe
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                style="width: 100%">
                <el-table-column
                    prop="name"
                    align="center"
                    label="标签名称">
                </el-table-column>
                <el-table-column
                    prop="color"
                    align="center"
                    label="标签颜色">
                    <template slot-scope="scope">
                        <div v-if="scope.row.color" class="all-flex">
                            <div class="color-box" :style="{'backgroundColor': scope.row.color}"></div>
                        </div>
                        <div v-else>暂无颜色</div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            @click="editJobLabel(scope.row)"
                            >修改
                        </el-button>
                        <el-button
                            type="danger"
                            v-if="scope.row.id!=4"
                            @click="deleteJobLabel(scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog
                :title="isFlag"
                :visible.sync="jobLabelVisible"
                width="30%">
                <el-form ref="JobLabelFormRef" :model="JobLabelForm" :rules="JobLabelFormRules" label-width="110px">
                    <el-form-item label="标签名称: " prop="name">
                        <el-input v-model="JobLabelForm.name" placeholder="请填写职位标签名称" :disabled="JobLabelForm.id==4"></el-input>
                    </el-form-item>
                    <el-form-item label="标签颜色: " prop="color">
                        <el-color-picker v-model="JobLabelForm.color"></el-color-picker>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="jobLabelVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveJobLabel">确 定</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div> 
</template>
<script>
import companyRequest from '../../api/company'
export default {
    mounted() {
        this.getAllJobTag()
    },
    data() {
        return {
            flag: true,
            jobLabelVisible: false, // 添加修改职位标签弹出框
            jobTags: [], //职位标签列表
            JobLabelForm: { //增加职位标签表单
                id: null,
                name: '',
                color: null
            },
            JobLabelFormRules: { //职位标签表单验证
                name: [{ required: true, message: '请填写职位标签名称', trigger: 'blur' },],
                color: [{ required: true, message: '请选择职位标签所属颜色', trigger: 'change' },]
            }
        }
    },
    methods: {
        openJobLabel() {
            this.flag = true
            this.jobLabelVisible = true
        },
        editJobLabel(row) {
            this.flag = false
            this.JobLabelForm.id = row.id
            this.JobLabelForm.name = row.name
            this.JobLabelForm.color = row.color
            this.jobLabelVisible = true
        },
        deleteJobLabel(row) {
            this.$confirm('此操作将永久删除该职位标签, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    companyRequest.deleteAllJobTag({id: row.id}).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('删除成功~')
                            this.getAllJobTag()
                        }
                    }).catch((err) => {
                        this.$message.error(err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })         
                })
        },
        // 保存或修改职位标签
        saveJobLabel() {
            console.log('JobLabelForm', this.JobLabelForm)
            this.$refs.JobLabelFormRef.validate((valid) => {
                if (valid) {
                    companyRequest.addWorkTag(this.JobLabelForm).then((res) => {
                        if (res.code === 200) {
                            const message = this.falg ? '添加' : '修改'   
                            this.$message.success(`${message}成功~`)
                            this.getAllJobTag()
                            this.jobLabelVisible = false
                        }
                    }).catch((err) => {
                        this.jobLabelVisible = false 
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
                })
        },
        //获取所有职位标签列表
        getAllJobTag() {
            companyRequest.getAllJobTag().then((res) => {
                if (res.code === 200) {
                    this.jobTags = res.data
                }
            }).catch((err) => {
                this.jobTags = []
            })
        }
    },
    computed: {
        isFlag() {
            return this.flag ? '新增职位标签': '修改职位标签'
        }
    },
}
</script>
<style lang="scss" scoped>
    .job-label{
        height: 100%;
        .create{
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-end;
        }
        .mycolor{
            color: #fff;
        }
        .box-card{
            margin-top: 20px;
        }
        .all-flex{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .color-box{
            width: 30px;
            height: 30px;
            border-radius: 2px;
        }
    }
</style>